define('yo-grade/components/app-page', ['exports', 'ember-gestures/mixins/recognizers', 'ember'], function (exports, _recognizers, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend(_recognizers.default, {
    recognizers: 'swipe',
    classNames: ['app-page'],
    title: null,
    classNameBindings: ['contentReady:hidden-preloader:visible-preloader'],
    contentReady: null
  });
});