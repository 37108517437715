define('yo-grade/controllers/forgot-password', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service;
  exports.default = Controller.extend({
    firebaseApp: service(),
    error_msg: '',
    success_sent: false,
    preloader: false,

    actions: {
      resetPassword: function resetPassword() {
        var self = this;
        var firebase = this.get('firebaseApp');
        var email = this.get('email');

        this.set('preloader', true);

        firebase.auth().sendPasswordResetEmail(email).then(function () {
          self.set('preloader', false);
          self.set('success_sent', true);
        }).catch(function (err) {
          self.set('preloader', false);
          self.set('error_msg', err.message);
        });
      },
      cancel: function cancel() {
        this.set('error_msg', '');
        this.set('email', '');
        this.set('success_sent', false);

        window.history.back();
      }
    }
  });
});