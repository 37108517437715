define('yo-grade/controllers/grading', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service;
  exports.default = Controller.extend({
    // firebaseApp: service(),
    grading: service(),
    checking: service(),
    workplace: service(),
    preloader: false,
    yesterdayInfo: false,

    actions: {
      toggleYesterdayInfo: function toggleYesterdayInfo() {
        this.toggleProperty('yesterdayInfo');
      }
    }
  });
});