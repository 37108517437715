define('yo-grade/services/time-chooser', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      get = _ember.default.get;
  exports.default = Service.extend({
    time: null,

    getTime: function getTime() {
      var time = get(this, 'time');
      return time.valueOf();
    }
  });
});