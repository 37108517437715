define('yo-grade/components/custom-dialog-actions', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      observer = _ember.default.observer,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Component.extend({
    active: false,
    confirmAction: null,

    waiting: false,
    success: false,
    error: false,
    successMsg: '',
    errorMsg: '',

    onDialogClose: observer('active', function () {
      var active = get(this, 'active');

      if (!active) {
        this.closeActions();
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.closeActions();
    },
    closeActions: function closeActions() {
      set(this, 'waiting', false);
      set(this, 'success', false);
      set(this, 'error', false);
    },


    actions: {
      closeDialog: function closeDialog() {
        set(this, 'active', false);
        this.closeActions();
        if (this.onClose) {
          this.onClose(this);
        }
      },
      closeActions: function closeActions() {
        this.closeActions();
      },
      confirm: function confirm() {
        this.confirmAction(this);
      }
    }
  });
});