define('yo-grade/controllers/business-profile', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      set = _ember.default.set,
      get = _ember.default.get;
  exports.default = Controller.extend({
    user: service(),
    firebaseApp: service(),
    avatar: service(),

    editPersonalInfo: false,
    editingAvatar: false,
    editingProfile: false,
    showAvatarDialog: false,

    personalInfoProps: ['name', 'address'],

    pi_waiting: false,
    pi_success: false,
    pi_error: false,
    pi_errorMsg: '',

    populateFields: function populateFields() {
      var self = this;
      var model = this.get('model');

      this.personalInfoProps.forEach(function (prop) {
        self.set(prop, model[prop]);
      });
    },
    updateUserProfile: function updateUserProfile(data) {
      var self = this;
      var uid = get(this, 'session.currentUser.uid');
      var firebaseApp = get(this, 'firebaseApp');

      set(self, 'pi_waiting', true);
      set(self, 'pi_error', false);
      set(self, 'pi_success', false);

      // console.log('###### updateBusinessProfile :', data);

      if (data.name === '') {
        set(self, 'pi_errorMsg', 'Business name can\'t be blank');
        set(self, 'pi_error', true);
      } else {
        firebaseApp.database().ref('businessProfiles/' + uid).update(data).then(function () {
          set(self, 'pi_success', true);
        }).catch(function (error) {
          set(self, 'pi_errorMsg', error);
          set(self, 'pi_error', true);
        });
      }
    },


    actions: {
      editProfile: function editProfile() {
        this.set('editPersonalInfo', true);
        this.populateFields();
      },
      saveBusinessProfile: function saveBusinessProfile() {
        var self = this;
        var model = this.get('model');
        var propsChanged = [];
        var propsToUpdate = {};

        console.log('##### SAVE PROFILE');

        this.personalInfoProps.forEach(function (prop) {
          if (model[prop] !== self[prop]) {
            propsChanged.push(prop);
          }
        });

        propsChanged.forEach(function (prop) {
          propsToUpdate[prop] = self[prop];
        });

        console.log(propsChanged);
        console.log(propsToUpdate);

        if (propsChanged.length === 0) {
          set(self, 'pi_waiting', true);
          set(self, 'pi_error', true);
          set(self, 'pi_errorMsg', 'No changes');
        } else {
          self.updateUserProfile(propsToUpdate);
        }
      },
      editAvatar: function editAvatar() {
        set(this, 'editingAvatar', true);
      },
      onAvatarChanged: function onAvatarChanged(data) {
        set(this, 'model.profile_image', data);
      }
    }
  });
});