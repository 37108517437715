define('yo-grade/services/checking', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Service.extend({
    session: service(),
    workplace: service(),
    firebaseApp: service(),

    ready: false,
    checkedIn: false,
    checkedInToday: false,
    checkedInAt: null,
    autoCheckOutAt: null,
    currentCheckInId: null,
    checkedOutAt: null,

    after_midnight_treshold: 3,

    history: [],

    initialize: function initialize() {
      var _this = this;

      var self = this;
      var history = get(this, 'history');
      var workplace = get(this, 'workplace');

      console.log('------------------------------------');
      console.log('# Service : Checking : initialize');
      console.log('------------------------------------');

      return new RSVP.Promise(function (resolve) {
        if (!workplace.active) {
          set(self, 'ready', true);
          console.log('# Service : Checking : NO WORKPLACE');
          console.log('# Service : Checking : READY');
          resolve();
        } else {

          self.getCheckIns().then(function (data) {
            //console.log(data);

            if (data) {
              var lastCheckIn = data[data.length - 1];

              var after_midnight_treshold = get(_this, 'after_midnight_treshold');
              var startToday = (0, _moment.default)().startOf('day').add(after_midnight_treshold, 'hours').format('x');

              if (lastCheckIn > startToday) {
                set(self, 'checkedInToday', true);
              }

              if (lastCheckIn.out > Date.now()) {
                set(self, 'checkedIn', true);
                set(self, 'currentCheckInId', lastCheckIn.id);
                set(self, 'checkedInAt', lastCheckIn.in);
                set(self, 'autoCheckOutAt', lastCheckIn.out);

                console.log('# Service : Checking : checkedIn :', true);

                if (data.length > 1) {
                  data.forEach(function (checkin, i) {
                    if (data.length - 1 > i) {
                      history.pushObject(checkin);
                    }
                  });
                }
              } else {
                set(self, 'checkedIn', false);
                set(self, 'checkedOutAt', lastCheckIn.out);
                set(self, 'history', data);

                console.log('# Service : Checking : checkedIn :', false);
              }
            }

            console.log('# Service : Checking : READY');
            set(self, 'ready', true);

            resolve();
          });
        }
      });
    },
    checkIn: function checkIn(checkOutMilis) {
      var self = this;
      var user_uid = get(this, 'session.currentUser.uid');
      var workplace_uid = get(this, 'workplace').data.business_id;
      var firebaseApp = get(this, 'firebaseApp');
      var checkInsRef = firebaseApp.database().ref('businessCheckIns').child(workplace_uid).child(user_uid);

      var now = Date.now();

      var data = {
        in: now,
        out: checkOutMilis
      };

      return checkInsRef.push(data).then(function (snap) {
        console.log(snap.key);
        set(self, 'checkedIn', true);
        set(self, 'checkedInAt', data.in);
        set(self, 'autoCheckOutAt', data.out);
        set(self, 'currentCheckInId', snap.key);
      });
    },
    checkOut: function checkOut(timestamp, type) {
      var self = this;
      var firebaseApp = get(this, 'firebaseApp');
      var user_uid = get(this, 'session.currentUser.uid');
      var workplace_uid = get(this, 'workplace').data.business_id;
      var history = get(this, 'history');
      var checkIn_id = get(this, 'currentCheckInId');
      var checkedInAt = get(this, 'checkedInAt');
      var checkInRef = firebaseApp.database().ref('businessCheckIns').child(workplace_uid).child(user_uid).child(checkIn_id);

      console.log('-----------------------------');
      console.log('timestamp:', timestamp);
      console.log('type:', type);
      console.log('checkIn_id:', checkIn_id);
      console.log('workplace_uid:', workplace_uid);
      console.log('user_uid:', user_uid);
      console.log('-----------------------------');

      if (type !== 'update') {
        set(self, 'checkedIn', false);
      }

      var outTime = timestamp || Date.now();

      return checkInRef.update({
        out: outTime

      }).then(function () {
        if (type === 'update') {
          set(self, 'autoCheckOutAt', outTime);
        } else {
          set(self, 'checkedOutAt', outTime);
          history.unshiftObject({
            in: checkedInAt,
            out: outTime
          });
        }
      });
    },
    getCheckIns: function getCheckIns() {
      var workplace_uid = get(this, 'workplace').data.business_id;
      var firebaseApp = get(this, 'firebaseApp');
      var uid = get(this, 'session.currentUser.uid');
      var userCheckins = firebaseApp.database().ref('businessCheckIns').child(workplace_uid).child(uid);

      return new RSVP.Promise(function (resolve, reject) {
        userCheckins.orderByKey().limitToLast(10).once('value').then(function (snap) {
          var data = snap.val();

          if (data) {
            var history = Object.keys(data).map(function (item) {
              return {
                in: data[item].in,
                out: data[item].out,
                id: item
              };
            });

            resolve(history);
          } else {
            resolve(null);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  });
});