define('yo-grade/services/business', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Service.extend({
    session: service(),
    firebaseApp: service(),
    firebaseUtil: service(),

    create: function create(params) {
      var self = this;

      return new RSVP.Promise(function (resolve, reject) {
        self._createFirebaseUser(params.email, params.password).then(function (user) {
          console.log('# 1 : created : firebase user');

          var uid = user.uid;

          var tasks = {
            account: self._createAccount(uid, params),
            profile: self._createProfile(uid, params)
          };

          RSVP.hash(tasks).then(function () {
            console.log('# 2 : created : account and profile');
            resolve();
          }).catch(function (err) {
            reject(err);
          });
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    _createFirebaseUser: function _createFirebaseUser(email, pass) {
      var auth = get(this, 'firebaseApp').auth();

      return auth.createUserWithEmailAndPassword(email, pass);
    },


    // --------------------------------------------
    // Create Business Account
    // --------------------------------------------

    _createAccount: function _createAccount(uid) {
      var firebaseApp = get(this, 'firebaseApp');
      var businessAccounts = firebaseApp.database().ref('businessAccounts');

      var data = {
        created: Date.now(),
        active: false
      };

      return businessAccounts.child(uid).set(data);
    },


    // --------------------------------------------
    // Create Business Profile
    // --------------------------------------------

    _createProfile: function _createProfile(uid, params) {
      var firebaseApp = get(this, 'firebaseApp');
      var businessProfiles = firebaseApp.database().ref('businessProfiles');

      var data = {
        created: new Date().getTime(),
        name: params.name,
        address: '',
        profile_image: ''
      };

      return businessProfiles.child(uid).set(data);
    }
  });
});