define('yo-grade/components/user-list-item', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Component.extend({
    classNames: ['user-list-item'],
    classNameBindings: ['clickable'],
    clickable: false,

    user: null,
    initials: null,
    action: null,
    icon: null,
    icon2: null,
    toggle: false,
    toggleState: false, // OFF true is ON
    order: false,
    orderIndex: null,
    orderNum: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var user = get(this, 'user');
      var initials = user.first_name[0] + '' + user.last_name[0];

      set(this, 'initials', initials);

      if (get(this, 'action') || get(this, 'toggle')) {
        set(this, 'clickable', true);
      }

      if (get(this, 'order')) {
        set(this, 'orderNum', get(this, 'orderIndex') + 1);
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      this._super.apply(this, arguments);

      this.$('.user-list-item-profile').on('click', function () {
        if (get(self, 'toggle')) {
          self.toggleProperty('toggleState');
        }

        if (get(self, 'action')) {
          self.action();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$('.user-list-item-profile').off('click');
    }
  });
});