define('yo-grade/services/avatar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      set = _ember.default.set;
  exports.default = Service.extend({
    show: false,
    showCropper: false,
    uploadedImageDataURL: null,

    open: function open() {
      set(this, 'show', true);
    },
    close: function close() {
      set(this, 'show', false);
      set(this, 'showCropper', false);
      set(this, 'uploadedImageDataURL', null);
    },
    setUploadedImage: function setUploadedImage(dataURL) {
      set(this, 'uploadedImageDataURL', dataURL);
      set(this, 'showCropper', true);
    }
  });
});