define('yo-grade/components/notify-item', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Component.extend({
    paperToaster: service(),
    notifications: service(),
    classNames: ['item'],
    item: null,

    respond: false,

    onDenyRequest: null,
    onApproveRequest: null,

    isInitial: true,
    isApprove: false,
    isDeny: false,

    isManager: false,

    selectedJobTitle: 'Server',

    jobTitles: ['Server', 'Lounge Server', 'Busser', 'Runner', 'Host', 'Expo', 'Bartender', 'Barback', 'Sommalier', 'General Manager', 'Assistant General Manager', 'Floor Manager', 'Event Manager'],

    // Staff: Server, lounge server, Busser, Runner, Host, Expo, Bartender, Barback, Sommalier;
    // Managers: GM, AGM, Floor manager, event manager;

    preloader: false,

    removeItem: function removeItem() {
      var notifications = get(this, 'notifications');
      var item = get(this, 'item');

      notifications.removeRequest(item);
    },


    actions: {
      toggleRespond: function toggleRespond() {
        this.toggleProperty('respond');

        set(this, 'isInitial', true);
        set(this, 'isApprove', false);
        set(this, 'isDeny', false);
      },
      addEmployee: function addEmployee() {
        if (get(this, 'preloader')) {
          return;
        }

        console.log('# Component : notifyItem : addEmployee');

        var self = this;
        var user = get(this, 'item');
        var notifications = get(this, 'notifications');
        var paperToaster = get(this, 'paperToaster');

        var jobTitle = get(this, 'selectedJobTitle');
        var isManager = get(this, 'isManager');

        var employeeName = user.first_name + ' ' + user.last_name;
        var successMsg = 'Successfully added ' + employeeName + ' as employee';
        var errorMsg = 'You already added ' + employeeName + ' to your employees list';

        set(self, 'preloader', true);

        notifications.addEmployee(user.sender_uid, jobTitle, isManager, user).then(function (exists) {
          var toasterText = exists ? errorMsg : successMsg;

          if (exists) {
            set(self, 'preloader', false);
            set(self, 'respond', false);
          }

          paperToaster.show(toasterText, {
            duration: 7000,
            position: 'bottom right'
          });
        });
      },
      approveRequest: function approveRequest() {
        set(this, 'isApprove', true);
        set(this, 'isDeny', false);
        set(this, 'isInitial', false);
      },
      promptDenyRequest: function promptDenyRequest() {
        set(this, 'isDeny', true);
        set(this, 'isApprove', false);
        set(this, 'isInitial', false);
      },
      denyRequest: function denyRequest() {
        var notifications = get(this, 'notifications');
        var request = get(this, 'item');

        notifications.denyRequest(request);
      },
      deleteMessage: function deleteMessage() {
        var item = get(this, 'item');
        var notifications = get(this, 'notifications');
        var paperToaster = get(this, 'paperToaster');

        var toasterText = 'Successfully deleted message from ' + item.name;

        set(this, 'preloader', true);

        notifications.deleteMessage(item).then(function () {
          paperToaster.show(toasterText, {
            duration: 7000,
            position: 'bottom right'
          });
        });
      }
    }
  });
});