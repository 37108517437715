define('yo-grade/helpers/arr', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arr = arr;
  function arr(params /*, hash*/) {
    return params;
  }

  exports.default = _ember.default.Helper.helper(arr);
});