define('yo-grade/controllers/employee-info', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Controller.extend({
    user: service(),
    employees: service(),

    removingEmployee: false,
    re_waiting: false,
    re_success: false,
    re_error: false,

    actions: {
      goBack: function goBack() {
        window.history.back();
      },
      promptRemoveEmployee: function promptRemoveEmployee() {
        set(this, 'removingEmployee', true);
      },
      removeEmployee: function removeEmployee(model) {
        var _this = this;

        var employees = get(this, 'employees');

        set(this, 're_waiting', true);

        employees.deleteEmployee(model).then(function () {
          set(_this, 're_success', true);
          window.history.back();
        });
      }
    }
  });
});