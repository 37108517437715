define('yo-grade/services/grading', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set,
      $ = _ember.default.$;
  exports.default = Service.extend({
    firebaseApp: service(),
    session: service(),
    workplace: service(),
    checking: service(),
    // overlap_treshold: 135, // minutes
    overlap_treshold: 1, // minutes
    after_midnight_treshold: 3, // hours
    yesterday: false,

    ready: false,
    gradableUsers: [],
    history: [],

    initialize: function initialize() {
      var self = this;
      var workplace = get(this, 'workplace');

      console.log('------------------------------------');
      console.log('# Service : Grading : initialize');
      console.log('------------------------------------');

      if (workplace.active) {
        this.buildGradableList().then(function (data) {
          set(self, 'gradableUsers', data.gradableUsers);
          set(self, 'history', data.history);
          set(self, 'ready', true);

          self.setYesterday();

          //console.log('# Service : Grading : data :', data);
          console.log('# Service : Grading : READY');
        });
      } else {
        set(self, 'ready', true);
      }
    },
    refreshGrading: function refreshGrading() {
      var self = this;

      return this.buildGradableList().then(function (data) {
        set(self, 'gradableUsers', data.gradableUsers);
        set(self, 'history', data.history);

        self.setYesterday();
      });
    },
    buildGradableList: function buildGradableList() {
      var self = this;
      var uid = get(this, 'session').get('uid');
      var checking = get(this, 'checking');
      var workplace = get(this, 'workplace');

      // console.log(checking);

      console.log('- workplace : active :', workplace.active);
      console.log('- checking : checkedIn :', checking.checkedIn);

      // default model
      var model = {
        gradableUsers: [],
        history: []
      };

      // -----------------------------------------------
      // Step: 1
      // -----------------------------------------------
      console.log('### STEP : 1 : get my todays checkins');

      // return new RSVP.Promise(resolve => {
      //   console.log('checkedIn :', checking.checkedIn);
      //   resolve();
      // });

      console.log('### UID :', uid);

      return self.getUserCheckIns(uid).then(function (myTodayCheckins) {
        console.log('--- myTodayCheckins :', myTodayCheckins.length);

        if (myTodayCheckins.length === 0) {
          console.log('### STOP : no todays checkins');
          return model;
        } else {
          // -------------------------------------------
          // Step: 2
          // -------------------------------------------
          console.log('### STEP : 2 : get coworkers');
          return self.getCoworkers().then(function (coworkers) {
            console.log('--- coworkers :', coworkers);

            if (!coworkers) {
              console.log('### STOP : No coworkers');
              return model;
            } else {
              // ---------------------------------------
              // Step: 3
              // ---------------------------------------
              console.log('### STEP : 3 : get history');
              return self.getHistory(coworkers).then(function (history) {
                console.log('--- history :', history.raw.length);

                if (history.profiles.length) {
                  model.history = history.profiles;
                }

                // ---------------------------------------
                // Step: 4
                // ---------------------------------------
                console.log('### STEP : 4  : get gradable users');
                return self.getGradableUsers(history.raw, coworkers, myTodayCheckins).then(function (gradableUsers) {
                  console.log('--- gradableUsers :', gradableUsers.length);
                  if (gradableUsers.length) {
                    model.gradableUsers = gradableUsers;
                  }

                  return model;
                });
              });
            }
          });
        }
      });
    },
    gradeUser: function gradeUser(uid, grade, comment) {
      console.log('### GRADE :', grade);
      console.log('### UID :', uid);
      console.log('### COMMENT :', comment ? true : false);

      var myuid = get(this, 'session.currentUser.uid');
      var business_id = get(this, 'workplace').data.business_id;
      var firebaseApp = get(this, 'firebaseApp');
      var db = firebaseApp.database();
      var publicGrades = db.ref('publicGrades').child(uid);
      var privateGrades = db.ref('privateGrades').child(myuid);
      var businessGrades = db.ref('businessGrades').child(business_id).child(uid);
      var now = Date.now();
      var gradableUsers = get(this, 'gradableUsers');
      var history = get(this, 'history');

      var public_data = {
        business_id: business_id,
        timestamp: now,
        value: grade
      };

      return publicGrades.push(public_data).then(function () {
        var private_data = {
          uid: uid,
          timestamp: now,
          value: grade
        };

        if (comment) {
          private_data.comment = comment;
        }

        return privateGrades.push(private_data);
      }).then(function () {
        if (comment) {
          public_data.comment = comment;
        }

        return businessGrades.push(public_data).then(function () {
          gradableUsers.forEach(function (profile) {
            if (profile.id === uid) {
              gradableUsers.removeObject(profile);
              profile.grade_value = grade;
              profile.grade_timestamp = now;

              var obj = {
                first_name: profile.first_name,
                last_name: profile.last_name,
                profile_image: profile.profile_image,
                grade_timestamp: now,
                grade_value: grade,
                nei: grade === 0 ? true : false,
                comment: comment || null
              };

              history.unshiftObject(obj);
            }
          });
        });
      });
    },
    setYesterday: function setYesterday() {
      var date = new Date();
      var hours = date.getHours();
      var after_midnight_treshold = get(this, 'after_midnight_treshold');

      console.log('# Service : Grading : hours :', hours);
      console.log('# Service : Grading : after_midnight_treshold :', after_midnight_treshold);

      if (hours < after_midnight_treshold) {
        var startDate = this.getStartTime('date');
        set(this, 'yesterday', startDate);
      } else {
        set(this, 'yesterday', false);
      }
    },
    getCoworkers: function getCoworkers() {
      var firebaseApp = get(this, 'firebaseApp');
      var workplace = get(this, 'workplace');
      var uid = get(this, 'session').get('uid');
      var business_id = workplace.data.business_id;
      var businessEmployeesRef = firebaseApp.database().ref('businessEmployees').child(business_id);

      return new RSVP.Promise(function (resolve) {
        businessEmployeesRef.once('value').then(function (snapshot) {
          var snap = snapshot.val();
          var ids = Object.keys(snap).filter(function (id) {
            return uid !== id ? id : false;
          });
          var profiles = [];

          //console.log(Object.keys(snap).length, ids.length);

          ids.forEach(function (id, index) {
            snap[id].id = id;

            //console.log(snap[id]);

            var employeeProfileRef = firebaseApp.database().ref('userProfiles').child(id);

            employeeProfileRef.once('value').then(function (snapshot) {
              var employeeProfile = snapshot.val();
              var joinedObj = Object.assign(employeeProfile, snap[id]);
              profiles.push(joinedObj);

              if (ids.length === index + 1) {
                //console.log('LLLLLLLLLLLLLLL:', objKeys);

                if (profiles.length === 0) {
                  resolve(false);
                } else {
                  resolve(profiles);
                }
              }
            });
          });
        });
      });
    },
    getStartTime: function getStartTime(type) {
      var date = new Date();
      var hours = date.getHours();
      // let dateNum = date.getDate();
      var after_midnight_treshold = get(this, 'after_midnight_treshold');

      if (hours < after_midnight_treshold) {
        date.setDate(date.getDate() - 1);
      }

      date.setHours(0, 0, 0, 0);

      if (!type) {
        return date.getTime();
      } else if (type === 'date') {
        var dateFormated = (0, _moment.default)(date).format('dddd, MMM Do');
        return dateFormated;
      }
    },
    getHistory: function getHistory(profiles) {
      var firebaseApp = get(this, 'firebaseApp');
      var uid = get(this, 'session').get('uid');
      var privateGradesRef = firebaseApp.database().ref('privateGrades').child(uid);
      var start = this.getStartTime();

      return privateGradesRef.orderByChild('timestamp').startAt(start).once('value').then(function (snapshot) {
        var snap = snapshot.val();

        var privateGrades = $.map(snap, function (grade) {
          return grade;
        });

        var historyProfiles = [];

        privateGrades.forEach(function (grade) {
          profiles.forEach(function (profile) {
            if (grade.uid === profile.id) {
              var obj = {
                first_name: profile.first_name,
                last_name: profile.last_name,
                profile_image: profile.profile_image,
                grade_timestamp: grade.timestamp,
                grade_value: grade.value,
                nei: grade.value === 0 ? true : false,
                comment: grade.comment || null
              };

              historyProfiles.push(obj);
            }
          });
        });

        return {
          profiles: historyProfiles,
          raw: privateGrades
        };
      });
    },
    getGradableUsers: function getGradableUsers(privateGrades, profiles, myTodayCheckins) {
      var self = this;

      // console.log('# Service : Grading : getGradableUsers');
      // console.log(privateGrades, profiles, myTodayCheckins);

      return new RSVP.Promise(function (resolve) {
        var gradableUsers = [];
        var gradableUsersReady = false;
        var now = Date.now();

        profiles.forEach(function (profile, index) {
          var graded = $.map(privateGrades, function (grade) {
            if (grade.uid === profile.id) {
              return profile.id;
            }
          });

          // console.log(graded);

          var gradedUserIndex = graded.indexOf(profile.id);

          // get checkins from profile
          self.getUserCheckIns(profile.id).then(function (checkins) {
            // check if user is checked in today
            if (checkins.length) {
              //console.log(checkins);

              // check if we already graded this user
              // if gradedUserIndex is not -1 we already graded user
              // checkins.forEach(otherCheckIn => {
              //   console.log(profile.first_name, moment(otherCheckIn.in).format('hh:mm:ss'));
              // });
              if (gradedUserIndex === -1) {
                var overlaps = 0;

                myTodayCheckins.forEach(function (myCheckIn, index) {
                  console.log('------------------------------------');
                  console.log(index, profile.first_name + ' ' + profile.last_name, ', checkins:', checkins.length);
                  console.log('------------------------------------');

                  checkins.forEach(function (otherCheckIn) {

                    var A = myCheckIn.in;
                    var B = myCheckIn.out < now ? myCheckIn.out : now;
                    //let B = myCheckIn.out;
                    var C = otherCheckIn.in;
                    var D = otherCheckIn.out < now ? otherCheckIn.out : now;
                    //let D = otherCheckIn.out;

                    console.log('MY : in  :', (0, _moment.default)(A).format('ddd, hh:mm:ss a'), '|| out :', (0, _moment.default)(B).format('ddd, hh:mm:ss a'));
                    console.log('-- : in  :', (0, _moment.default)(C).format('ddd, hh:mm:ss a'), '|| out :', (0, _moment.default)(D).format('ddd, hh:mm:ss a'));

                    //http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap/325964#325964

                    if (!(B <= C || A >= D)) {
                      var overlaptime = Math.min.apply(Math, [B - A, B - C, D - C, D - A]);
                      var ovelap_minutes = overlaptime / 1000 / 60;
                      overlaps = overlaps + ovelap_minutes;
                      console.log('OVERLAP: ', ovelap_minutes);
                      if (overlaps >= get(self, 'overlap_treshold')) {

                        // check if already in pushed
                        var already_pushed = false;

                        gradableUsers.forEach(function (user) {
                          if (user.id === profile.id) {
                            already_pushed = true;
                          }
                        });

                        if (!already_pushed) {
                          gradableUsers.push(profile);
                        }
                      }
                    }
                  });
                });
              } else {
                // already graded
                console.log('--- already graded:', graded[gradedUserIndex]);
              }
            }

            if (index === profiles.length - 1) {
              gradableUsersReady = true;
            }
          });
        });

        function isGradableUsersReady() {
          if (gradableUsersReady) {
            console.log('--- gradableUsersReady');
            resolve(gradableUsers);
          } else {
            setTimeout(function () {
              isGradableUsersReady();
            }, 100);
          }
        }

        isGradableUsersReady();
      });
    },
    getUserCheckIns: function getUserCheckIns(uid) {
      var workplace = get(this, 'workplace');
      var firebaseApp = get(this, 'firebaseApp');
      var business_id = workplace.data.business_id;
      var refcheckIns = firebaseApp.database().ref('businessCheckIns').child(business_id).child(uid);
      var start = this.getStartTime();

      return new RSVP.Promise(function (resolve, reject) {
        refcheckIns.orderByChild('in').startAt(start).once('value').then(function (snapshot) {
          var snap = snapshot.val();
          var checkIns = $.map(snap, function (checkIn) {
            return checkIn;
          });
          resolve(checkIns);
        }).catch(function (error) {
          reject();
          console.log(error);
        });
      });
    }
  });
});