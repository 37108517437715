define('yo-grade/components/avatar-upload-box', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service,
      Component = _ember.default.Component,
      get = _ember.default.get;
  exports.default = Component.extend({
    avatar: service(),
    classNames: ['avatar-upload-box'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var avatar = get(this, 'avatar');

      this.$('#local_image').on('change', function (e) {
        var file = e.target.files[0];
        var reader = new FileReader();

        reader.onload = function (e) {
          avatar.setUploadedImage(e.target.result);
        };

        reader.readAsDataURL(file);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('#local_image').off('change');
    }
  });
});