define('yo-grade/components/comment-box', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    classNames: ['comment-box', 'layout-row', 'layout-align-center-center'],
    classNameBindings: ['folded'],
    folded: true,

    click: function click() {
      this.toggleProperty('folded');
    }
  });
});