define('yo-grade/controllers/sign-up-business', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Controller.extend({
    //app_controler: controller('application'),
    business: service(),
    user: service(),
    preloader: false,
    error_msg: '',
    hideTemplate: false,

    actions: {
      signUpBusiness: function signUpBusiness() {
        console.log('##### signUpBusiness');
        var self = this;
        var email = get(this, 'email');
        var pass = get(this, 'password');
        var name = get(this, 'name');
        var business = get(this, 'business');
        var user = get(this, 'user');

        set(this, 'preloader', true);

        var params = {
          email: email,
          password: pass,
          name: name
        };

        business.create(params).then(function () {
          console.log('# Sign Up Business : user data created');

          self.get('session').open('firebase', {
            provider: 'password',
            email: email,
            password: pass

          }).then(function () {
            console.log('# Sign Up Business : user logged in');

            self.set('hideTemplate', true);

            user.setup().then(function () {
              set(self, 'preloader', false);
              self.replaceRoute('ranking');
            });
          }).catch(function (err) {
            console.log(err);
            set(self, 'preloader', false);
            set(self, 'error_msg', err.message);
          });
        }).catch(function (err) {
          console.log(err);
          set(self, 'preloader', false);
          set(self, 'error_msg', err.message);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('sign-in');
      }
    }
  });
});