define('yo-grade/controllers/sign-in', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Controller.extend({
    session: service(),
    user: service(),
    firebaseApp: service(),
    error_msg: '',
    preloader: false,
    hideTemplate: false,

    actions: {
      signIn: function signIn() {
        var self = this;
        var session = get(this, 'session');
        var user = get(this, 'user');

        self.set('preloader', true);

        session.open('firebase', {
          provider: 'password',
          email: self.get('email'),
          password: self.get('password')

        }).then(function () {
          self.set('email', '');
          self.set('password', '');
          self.set('error_msg', '');

          self.set('preloader', false);
          self.set('hideTemplate', true);

          user.setup().then(function () {
            if (user.accountType.user) {
              self.replaceRoute('checking');
            } else if (user.accountType.business) {
              self.replaceRoute('ranking');
            }
          });
        }).catch(function (err) {
          console.log(err);

          self.set('preloader', false);
          self.set('error_msg', err.message);
        });
      },
      forgottenPassword: function forgottenPassword() {
        this.transitionToRoute('forgot-password');
      },
      signUp: function signUp() {
        this.transitionToRoute('sign-up');
      },
      signUpBusiness: function signUpBusiness() {
        this.transitionToRoute('sign-up-business');
      }
    }
  });
});