define('yo-grade/controllers/account', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Controller.extend({
    user: service(),
    firebaseApp: service(),
    session: service(),
    paperToaster: service(),

    currentEmail: '',
    emailVerified: false,
    editEmail: false,
    newEmail: '',
    verifyEmailSent: false,

    newPassword: '',
    editPassword: false,
    password: '',
    passwordChanged: false,

    error_msg: '',
    success_msg: '',
    preloader: false,
    preloader2: false,

    loginUser: function loginUser() {
      var firebase = get(this, 'firebaseApp');
      var pass = get(this, 'password');
      var email = get(this, 'session.currentUser.email');
      //let emailVerified = get(this, 'session.currentUser.emailVerified');

      return new RSVP.Promise(function (resolve, reject) {
        firebase.auth().signInWithEmailAndPassword(email, pass).then(function () {
          resolve();
        }).catch(function (error) {
          reject(error.message);
        });
      });
    },
    clearEmailForm: function clearEmailForm() {
      this.set('newEmail', '');
      this.set('password', '');
      this.set('success_msg', '');
      this.set('error_msg', '');
      this.set('editEmail', false);
    },
    clearPasswordForm: function clearPasswordForm() {
      this.set('newPassword', '');
      this.set('password', '');
      this.set('success_msg', '');
      this.set('error_msg', '');
      this.set('passwordChanged', false);
      this.set('editPassword', false);
    },
    clearForms: function clearForms() {
      this.clearEmailForm();
      this.clearPasswordForm();
    },


    actions: {
      // EMAIL
      editEmail: function editEmail() {
        this.set('editEmail', true);
        this.clearPasswordForm();
      },
      verifyEmail: function verifyEmail() {
        var self = this;
        var user = get(this, 'session.currentUser');
        var paperToaster = get(this, 'paperToaster');

        set(self, 'preloader2', true);

        user.sendEmailVerification().then(function () {
          set(self, 'preloader2', false);
          set(self, 'verifyEmailSent', true);

          var message = 'Verification sent, please check your email.';

          paperToaster.show(message, {
            duration: 7000,
            position: 'bottom right'
          });
        }).catch(function (error) {
          set(self, 'preloader2', false);
          var message = error.message;

          console.log(error.message);

          paperToaster.show(message, {
            duration: 7000,
            position: 'bottom right'
          });
        });
      },
      cancelEditEmail: function cancelEditEmail() {
        this.clearEmailForm();
      },
      saveNewEmail: function saveNewEmail() {
        var self = this;
        var user = get(this, 'session.currentUser');
        var newEmail = get(this, 'newEmail');

        set(this, 'preloader', true);

        this.loginUser().then(function () {
          user.updateEmail(newEmail).then(function () {
            //let msg = 'You successfully changed your email address.';
            set(self, 'preloader', false);
            set(self, 'currentEmail', user.email);
            set(self, 'emailVerified', user.emailVerified);
          }).catch(function (err) {
            set(self, 'preloader', false);
            set(self, 'error_msg', err);
          });
        }).catch(function (err) {
          set(self, 'preloader', false);
          set(self, 'error_msg', err);
        });
      },


      // PAASWORD
      forgotPassword: function forgotPassword() {
        this.transitionToRoute('forgot-password');
      },
      editPassword: function editPassword() {
        this.set('editPassword', true);
        this.clearEmailForm();
      },
      cancelEditPassword: function cancelEditPassword() {
        this.clearPasswordForm();
      },
      saveNewPassword: function saveNewPassword() {
        var self = this;
        var user = get(this, 'session.currentUser');
        var newPassword = get(this, 'newPassword');

        set(this, 'preloader', true);

        this.loginUser().then(function () {
          user.updatePassword(newPassword).then(function () {
            set(self, 'password', '');
            set(self, 'newPassword', '');
            set(self, 'preloader', false);
            set(self, 'passwordChanged', true);
            set(self, 'success_msg', 'You successfully changed you password');
          }).catch(function (err) {
            set(self, 'preloader', false);
            set(self, 'error_msg', err);
          });
        }).catch(function (err) {
          set(self, 'preloader', false);
          set(self, 'error_msg', err);
        });
      }
    }
  });
});