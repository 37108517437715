define('yo-grade/controllers/sign-up', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      set = _ember.default.set;
  exports.default = Controller.extend({
    user: service(),
    preloader: false,
    error_msg: '',

    actions: {
      createUser: function createUser() {
        var self = this;
        var first_name = this.get('first_name');
        var last_name = this.get('last_name');
        var username = this.get('username');
        var email = this.get('email');
        var pass = this.get('password');
        var user = this.get('user');

        set(this, 'preloader', true);

        var params = {
          email: email,
          password: pass,
          username: username,
          first_name: first_name,
          last_name: last_name
        };

        user.create(params).then(function () {
          console.log('# Sign Up : user data created');

          self.get('session').open('firebase', {
            provider: 'password',
            email: email,
            password: pass

          }).then(function () {
            console.log('# Sign Up : user logged in');
            user.setup();
            set(self, 'preloader', false);
            self.replaceRoute('checking');
          }).catch(function (err) {
            console.log(err);
            set(self, 'preloader', false);
            set(self, 'error_msg', err.message);
          });
        }).catch(function (err) {
          console.log(err);
          set(self, 'preloader', false);
          set(self, 'error_msg', err.message);
        });
      },
      cancelSignUp: function cancelSignUp() {
        this.transitionToRoute('sign-in');
      }
    }
  });
});