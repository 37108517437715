define('yo-grade/controllers/checking', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      set = _ember.default.set,
      get = _ember.default.get;
  exports.default = Controller.extend({
    user: service(),
    checking: service(),
    workplace: service(),
    timeChooser: service(),

    preloader: false,
    checkout_preloader: false,

    checkingIn: false,
    checkingOut: false,
    editingAutoCheckOut: false,

    checkedOut_value: false,

    co_waiting: false,
    co_success: false,
    co_error: false,
    co_errorMsg: '',

    ci_waiting: false,
    ci_success: false,
    ci_error: false,
    ci_errorMsg: '',

    ua_waiting: false,
    ua_success: false,
    ua_error: false,
    ua_errorMsg: '',

    actions: {
      checkInEdit: function checkInEdit() {
        var dateNow = new Date();
        var workingHoursDefault = get(this, 'workingHours');
        var currentHours = dateNow.getHours();
        var currentMinutes = dateNow.getMinutes();

        set(this, 'autoCheckOutHours', currentHours + workingHoursDefault);
        set(this, 'autoCheckOutMinutes', currentMinutes);

        set(this, 'checkingIn', true);
      },
      checkIn: function checkIn() {
        var self = this;
        var checking = this.get('checking');
        var timeChooser = this.get('timeChooser');

        set(self, 'ci_waiting', true);

        var milis = timeChooser.getTime();

        console.log(milis);

        checking.checkIn(milis).then(function () {
          set(self, 'ci_success', true);
        }).catch(function (error) {
          set(self, 'ci_error', true);
          set(self, 'ci_errorMsg', error);
        });
      },
      checkOutEdit: function checkOutEdit() {
        set(this, 'checkingOut', true);
      },
      checkOut: function checkOut() {
        var self = this;
        var checking = get(this, 'checking');

        set(this, 'co_waiting', true);

        checking.checkOut().then(function () {
          set(self, 'co_success', true);
        }).catch(function (error) {
          set(self, 'co_errorMsg', error);
          set(self, 'co_error', true);
        });
      },
      editAutoCheckOut: function editAutoCheckOut() {
        set(this, 'editingAutoCheckOut', true);
      },
      updateAutoCheckOut: function updateAutoCheckOut() {
        var self = this;
        var checking = get(this, 'checking');
        var timeChooser = get(this, 'timeChooser');
        var timestamp = timeChooser.getTime();

        set(this, 'ua_waiting', true);

        checking.checkOut(timestamp, 'update').then(function () {
          set(self, 'ua_success', true);
        }).catch(function (error) {
          set(self, 'ua_errorMsg', error);
          set(self, 'ua_error', true);
        });
      }
    }
  });
});