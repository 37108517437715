define('yo-grade/components/avatar-cropper', ['exports', 'ember-cli-image-cropper/components/image-cropper', 'ember'], function (exports, _imageCropper, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = _imageCropper.default.extend({
    avatar: service(),
    user: service(),
    firebaseApp: service(),
    session: service(),

    classNames: ['avatar-cropper-box'],

    // cropper js options
    aspectRatio: 1,
    autoCropArea: 1,
    cropperContainer: '.cropper-container > img',
    previewClass: '.img-preview',
    cropBoxMovable: false,
    cropBoxResizable: false,
    toggleDragModeOnDblclick: false,
    dragMode: 'move',

    error_msg: false,
    success_msg: false,
    uploading: false,

    savingAvatar: false,
    savingSuccess: false,
    savingFail: false,

    showAvatarDialog: null,

    actions: {
      saveImage: function saveImage() {
        var self = this;
        var session = get(this, 'session');
        var user = get(this, 'user');
        var currentUser = session.get('currentUser');
        var container = this.$(this.get('cropperContainer'));
        var firebaseApp = get(this, 'firebaseApp');
        var storageRef = firebaseApp.storage().ref();

        console.log('KKKKKKKKKKKKKKKKKK : SAVE IMAGE');

        set(this, 'savingAvatar', true);

        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
            value: function value(callback) {

              var binStr = atob(this.toDataURL('image/jpeg', 0.6).split(',')[1]);
              var len = binStr.length;
              var arr = new Uint8Array(len);

              for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
              }

              callback(new Blob([arr], { type: 'image/jpeg' }));
            }
          });
        }

        container.cropper('getCroppedCanvas', {
          width: 400,
          height: 400

        }).toBlob(function (blob) {
          console.log('# AVATRA CROPPER : container.cropper.toBlob :', user.accountType.business);
          var avatarRef = storageRef.child('avatars/' + currentUser.uid + '.jpg');
          var metadata = {
            contentType: 'image/jpeg'
          };

          avatarRef.put(blob, metadata).then(function (snapshot) {
            console.log('KKKKKKKKKKKKKKKKKK:', user.accountType.business);
            var profilesRefStr = user.accountType.business ? 'businessProfiles' : 'userProfiles';
            var profileRef = firebaseApp.database().ref(profilesRefStr + '/' + currentUser.uid);

            console.log(profilesRefStr);

            profileRef.update({
              profile_image: snapshot.downloadURL

            }).then(function () {
              set(self, 'savingSuccess', true);

              self.sendAction('action', snapshot.downloadURL);
            }).catch(function () {
              set(self, 'savingFail', true);
            });
          }).catch(function () {
            set(self, 'savingFail', true);
          });
        }, 'image/jpeg', 0.6);
      },
      closeAvatarDialog: function closeAvatarDialog() {
        console.log('closeAvatarDialog');
        var avatar = get(this, 'avatar');
        avatar.close();
        set(this, 'showAvatarDialog', false);
      },
      rotate: function rotate(direction) {
        var container = this.$(this.get('cropperContainer'));

        if (direction === 'right') {
          container.cropper('rotate', 15);
        } else {
          container.cropper('rotate', -15);
        }
      },
      zoom: function zoom(direction) {
        var container = this.$(this.get('cropperContainer'));

        if (direction === 'in') {
          container.cropper('zoom', 0.1);
        } else {
          container.cropper('zoom', -0.1);
        }
      }
    }
  });
});