define('yo-grade/initializers/firebase-flex', ['exports', 'emberfire-utils/initializers/firebase-flex'], function (exports, _firebaseFlex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _firebaseFlex.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _firebaseFlex.initialize;
    }
  });
});