define('yo-grade/controllers/ranking', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service;
  exports.default = Controller.extend({
    ranking: service(),
    workplace: service(),
    user: service()
  });
});