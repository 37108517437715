define('yo-grade/helpers/ms-date', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.msDate = msDate;
  function msDate(params) {

    var ms = params[0];
    var date = new Date(ms);

    console.log(date);

    return date;
  }

  exports.default = _ember.default.Helper.helper(msDate);
});