define('yo-grade/routes/forgot-password', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    deactivate: function deactivate() {
      this.controller.set('email', '');
      this.controller.set('error_msg', '');
      this.controller.set('success_sent', false);
    }
  });
});