define('yo-grade/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Controller.extend({
    session: service(),

    actions: {
      signOut: function signOut() {
        var _this = this;

        get(this, 'session').close().then(function () {
          _this.transitionToRoute('sign-in');
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        });
      },
      goToChecking: function goToChecking() {
        this.transitionToRoute('checking');
      },
      goToNotifications: function goToNotifications() {
        this.transitionToRoute('notifications');
      }
    }
  });
});