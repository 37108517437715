define('yo-grade/routes/business-profile', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service,
      get = _ember.default.get,
      Route = _ember.default.Route;
  exports.default = Route.extend({
    //session: service(),
    user: service(),
    //firebaseApp: service(),

    model: function model() {
      var user = get(this, 'user');

      return user.get('businessProfile');
    }
  });
});