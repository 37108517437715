define('yo-grade/ember-gestures/recognizers/swipe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    include: [],
    exclude: [],
    options: {
      threshold: 25,
      direction: typeof window.Hammer === 'undefined' ? '' : window.Hammer.DIRECTION_HORIZONTAL },
    recognizer: 'swipe'
  };
});