define('yo-grade/services/workplace', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Service.extend({
    firebaseApp: service(),
    session: service(),
    employees: service(),
    checking: service(),
    notifications: service(),
    user: service(),

    data: null,
    ready: false,
    active: false,

    _onReadyFunctions: [],

    _isReady: function _isReady() {
      var funcs = get(this, '_onReadyFunctions');

      if (funcs.length === 1) {
        funcs[0]();
      } else if (funcs.length > 1) {
        funcs.forEach(function (fn) {
          fn();
        });
      }
    },
    onReady: function onReady(func) {
      this._onReadyFunctions.addObject(func);
    },
    initialize: function initialize() {
      var _this = this;

      var self = this;
      var firebaseApp = get(this, 'firebaseApp');
      // let employees = get(this, 'employees');
      var user = get(this, 'user');
      //let checking = get(this, 'checking');
      var uid = get(this, 'session.currentUser.uid');
      var userWorkplaces = firebaseApp.database().ref('userWorkplaces').child(uid);

      console.log('------------------------------------');
      console.log('# Service : Workplace : initialize');
      console.log('------------------------------------');

      // console.log('# Service : Workplace : USER is BUSINESS :', user.accountType.business);

      return new RSVP.Promise(function (resolve) {
        // user is business so set workplace proprely
        if (user.accountType.business) {
          set(_this, 'active', true);
          set(_this, 'ready', true);
          // set(this, 'data', )
          self._isReady();
          resolve();
          return;
        }

        userWorkplaces.on('value', function (snap) {
          var val = snap.val();

          if (val) {
            var keys = Object.keys(val);

            keys.forEach(function (key) {
              var pending = val[key].pending;
              //console.log('# Service : Workplace : pending :', val[key].pending);

              var businessProfiles = firebaseApp.database().ref('businessProfiles').child(key);
              var businessEmployees = firebaseApp.database().ref('businessEmployees').child(key).child(uid);

              businessProfiles.once('value', function (snap) {
                //console.log('# Service : Workplace : businessProfile :', snap.val());

                var businessObj = snap.val();

                if (pending) {
                  businessObj.pending = true;
                  businessObj.business_id = snap.key;
                  businessObj.request_id = val[key].request_id;
                  set(self, 'data', businessObj);
                  set(self, 'ready', true);

                  console.log('# Service : Workplace : active :', false);
                  console.log('# Service : Workplace : READY');

                  self._isReady();

                  resolve(businessObj);
                } else {
                  businessEmployees.once('value', function (snap) {
                    //console.log('businessEmployees :', snap.val());

                    if (!snap.val()) {
                      set(self, 'data', null);
                      set(self, 'ready', true);

                      console.log('# Service : Workplace : active :', false);
                      console.log('# Service : Workplace : READY');

                      self._isReady();

                      resolve(null);
                    } else {
                      businessObj.pending = false;
                      businessObj.business_id = key;
                      businessObj.created = snap.val().created;
                      businessObj.job_title = snap.val().job_title;
                      businessObj.manager = snap.val().manager;
                      set(self, 'data', businessObj);
                      set(self, 'active', true);
                      set(self, 'ready', true);

                      console.log('# Service : Workplace : active :', true);
                      console.log('# Service : Workplace : READY');

                      self._isReady();

                      resolve(businessObj);
                    }
                  });
                }
              });
            });
          } else {
            set(self, 'data', null);
            set(self, 'active', false);
            set(self, 'ready', true);

            console.log('# Service : Workplace : active :', false);
            console.log('# Service : Workplace : READY');

            resolve(null);
          }
        });
      });
    },
    sendRequest: function sendRequest(userId, businessId) {
      //let self = this;
      var firebaseApp = get(this, 'firebaseApp');
      var businessRequests = firebaseApp.database().ref('businessRequests');
      var userWorkplaces = firebaseApp.database().ref('userWorkplaces');

      var businessData = {
        timestamp: Date.now(),
        sender_uid: userId
      };

      return new RSVP.Promise(function (resolve, reject) {
        businessRequests.child(businessId).push(businessData).then(function (snap) {
          var userData = {
            pending: true,
            request_id: snap.key
          };

          userWorkplaces.child(userId).child(businessId).set(userData).then(function () {
            resolve();
          });
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    cancelRequest: function cancelRequest() {
      var self = this;
      var data = get(this, 'data');
      var firebaseApp = get(this, 'firebaseApp');
      var notifications = get(this, 'notifications');
      var uid = get(this, 'session.currentUser.uid');
      var userWorkplacesRef = firebaseApp.database().ref('userWorkplaces').child(uid).child(data.business_id);
      var businessRequestsRef = firebaseApp.database().ref('businessRequests').child(data.business_id).child(data.request_id);

      console.log(data);

      return new RSVP.Promise(function (resolve) {
        businessRequestsRef.remove().then(function () {
          userWorkplacesRef.remove().then(function () {
            notifications.sendMessage(data.business_id, 'I canceled request!').then(function () {
              set(self, 'data', null);
              set(self, 'active', false);
              resolve();
            });
          });
        });
      });
    },
    cancelEmployement: function cancelEmployement() {
      var self = this;
      var data = get(this, 'data');
      var notifications = get(this, 'notifications');
      var firebaseApp = get(this, 'firebaseApp');
      var uid = get(this, 'session.currentUser.uid');
      var businessEmployeesRef = firebaseApp.database().ref('businessEmployees').child(data.business_id).child(uid);
      var businessCheckInsRef = firebaseApp.database().ref('businessCheckIns').child(data.business_id).child(uid);
      var userWorkplacesRef = firebaseApp.database().ref('userWorkplaces').child(uid).child(data.business_id);

      console.log(self.data);

      return new RSVP.Promise(function (resolve) {
        businessEmployeesRef.remove().then(function () {
          userWorkplacesRef.remove().then(function () {
            businessCheckInsRef.remove().then(function () {
              notifications.sendMessage(data.business_id, 'I canceled employement!').then(function () {
                set(self, 'data', null);
                set(self, 'active', false);
                resolve();
              });
            });
          });
        });
      });
    }
  });
});