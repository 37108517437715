define('yo-grade/routes/account', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Route.extend({
    user: service(),

    model: function model() {
      var user = get(this, 'user');

      return user.get('account');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var email = get(this, 'session.currentUser.email');
      var verified = get(this, 'session.currentUser.emailVerified');

      controller.set('currentEmail', email);
      controller.set('emailVerified', verified);
    },
    deactivate: function deactivate() {
      this.controller.clearForms();
    }
  });
});