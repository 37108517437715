define('yo-grade/controllers/workplace', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      set = _ember.default.set,
      get = _ember.default.get,
      $ = _ember.default.$;
  exports.default = Controller.extend({
    firebaseApp: service(),
    user: service(),
    workplace: service(),

    findingWorkplace: false,
    searchQuery: '',
    searchResults: null,
    selectedBusiness: null,

    waiting: false,
    success: false,
    error: false,
    errorMsg: 'Failed to send request',

    cancelingEmpolyement: false,
    ce_waiting: false,
    ce_success: false,
    ce_error: false,

    cancelingRequest: false,
    cr_waiting: false,
    cr_success: false,
    cr_error: false,

    sendRequest: function sendRequest(dialog) {
      var target = dialog._targetObject;
      //let query = target.searchQuery;
      var selectedBusiness = target.selectedBusiness;

      console.log('### sendRequest : selectedBusiness :', selectedBusiness);

      dialog.set('waiting', true);

      if (!selectedBusiness) {
        //dialog.set('waiting', false);
        dialog.set('error', true);
        dialog.set('errorMsg', 'You did not select any business to send request to');
      } else {
        var userId = target.session.content.uid;
        var businessId = selectedBusiness.uid;

        target.sendRequestToBusiness(userId, businessId).then(function () {
          dialog.set('success', true);
        }).catch(function () {
          dialog.set('error', true);
          dialog.set('errorMsg', 'Sending request failed! Please try again later.');
        });
      }
    },
    sendRequestToBusiness: function sendRequestToBusiness(userId, businessId) {
      var workplace = get(this, 'workplace');

      return workplace.sendRequest(userId, businessId);
    },
    onCancelDialog: function onCancelDialog(dialog) {
      var self = dialog._targetObject;

      set(self, 'searchQuery', '');
      set(self, 'searchResults', null);
      set(self, 'selectedBusiness', null);
    },


    actions: {
      selectBusiness: function selectBusiness(item) {
        $('.busines-search-results .item').each(function () {
          $(this).toggleClass('selected', false);
        });

        $('#' + item.uid).toggleClass('selected', true);
        set(this, 'selectedBusiness', item);
      },
      showFinder: function showFinder() {
        set(this, 'findingWorkplace', true);
      },
      queryChanged: function queryChanged(data) {
        var self = this;
        set(this, 'searchQuery', data);

        if (data.length > 2) {
          var firebaseApp = get(this, 'firebaseApp');
          var businessProfiles = firebaseApp.database().ref('businessProfiles');

          var query = data.charAt(0).toUpperCase() + data.slice(1);

          businessProfiles.orderByChild('name').startAt(query).endAt(query + '\uF8FF').once('value').then(function (snap) {
            if (snap.val() !== null) {
              var results = [];

              Object.keys(snap.val()).forEach(function (key) {

                var obj = snap.val()[key];
                obj.uid = key;
                results.push(obj);
              });

              set(self, 'searchResults', results);
            } else {
              set(self, 'searchResults', null);
            }
          });
        } else if (data.length === 0) {
          set(self, 'searchResults', null);
          set(self, 'selectedBusiness', null);
        }
      },
      cancelEmployement: function cancelEmployement() {
        var _this = this;

        var workplace = get(this, 'workplace');

        set(this, 'ce_waiting', true);

        workplace.cancelEmployement().then(function () {
          set(_this, 'ce_success', true);
        });
      },
      promptCancelEmployement: function promptCancelEmployement() {
        set(this, 'cancelingEmpolyement', true);
      },
      cancelRequest: function cancelRequest() {
        var _this2 = this;

        var workplace = get(this, 'workplace');

        set(this, 'cr_waiting', true);

        workplace.cancelRequest().then(function () {
          set(_this2, 'cr_success', true);
        });
      },
      promptCancelRequest: function promptCancelRequest() {
        set(this, 'cancelingRequest', true);
      }
    }
  });
});