define('yo-grade/components/input-password', ['exports', 'ember-paper/components/paper-input'], function (exports, _paperInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paperInput.default.extend({
    passwordIcon: 'visibility',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('input').attr({
        autocomplete: "off",
        autocorrect: "off",
        autocapitalize: "off",
        spellcheck: "false"
      });
    },


    actions: {
      togglePasswordVisibility: function togglePasswordVisibility() {
        var $input = this.$('input');
        var type = $input.attr('type');

        if (type === 'text') {
          this.set('passwordIcon', 'visibility');
          $input.attr('type', 'password');
        } else if (type === 'password') {
          this.set('passwordIcon', 'visibility-off');
          $input.attr('type', 'text');
        }
      }
    }
  });
});