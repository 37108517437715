define('yo-grade/controllers/employees', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service;
  exports.default = Controller.extend({
    employees: service(),
    preserveScrollPosition: true,

    actions: {
      showInfo: function showInfo(employee) {
        // console.log(employee);
        this.transitionToRoute('employee-info', employee.uid);
      },
      refreshList: function refreshList() {}
    }
  });
});