define('yo-grade/routes/application', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Route.extend({
    user: service(),

    beforeModel: function beforeModel() {
      return this.get('session').fetch().catch(function () {});
    },

    afterModel: function afterModel(model, transition) {
      var _this = this;

      var self = this;
      var target = _ember.default.get(transition, 'targetName');
      var isAuthenticated = this.get('session').get('isAuthenticated');
      console.log('# Route : Application : target :', target);

      if (!isAuthenticated) {
        this.replaceWith('sign-in');
      } else {
        var user = get(this, 'user');

        user.setup().then(function () {
          var accountType = get(self, 'user').accountType;

          //console.log('accountType :', accountType);

          if (accountType.user) {
            if (target === 'index' || target === 'sign-in' || target === 'sign-up') {
              _this.replaceWith('checking');
            }
          } else if (accountType.business) {
            if (target === 'index' || target === 'sign-in' || target === 'sign-up') {
              _this.replaceWith('ranking');
            }
          }

          // if (target === 'employee-info') {
          //   this.replaceWith('employees');
          // }
        });
      }
    }
  });
});