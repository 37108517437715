define('yo-grade/services/notifications', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      observer = _ember.default.observer,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Service.extend({
    session: service(),
    user: service(),
    firebaseApp: service(),

    ready: false,

    total: 0,

    requests: [],
    messages: [],

    onMessagesChanged: observer('messages', 'requests', function () {
      var requests = get(this, 'requests');
      var messages = get(this, 'messages');

      //console.log('######### TOTAL CHANGED');
      //console.log(messages.length, requests.length);

      set(this, 'total', messages.length + requests.length);
    }),

    initialize: function initialize() {
      var self = this;
      var accountType = get(this, 'user').accountType;

      console.log('------------------------------------');
      console.log('# Service : Notifications : initialize');
      console.log('------------------------------------');

      var promises = void 0;

      if (accountType.business) {
        promises = {
          messages: self.handleMessages(),
          requests: self.handleRequests()
        };
      } else {
        promises = {
          messages: self.handleMessages()
        };
      }

      RSVP.hash(promises).then(function () {
        console.log('# Service : Notifications : READY');
        set(self, 'ready', true);
      });
    },
    handleRequests: function handleRequests() {
      var self = this;
      var uid = get(this, 'session').get('uid');
      var firebaseApp = get(this, 'firebaseApp');
      var businessRequestsRef = firebaseApp.database().ref('businessRequests');

      console.log('# Service : Notifications : handleRequests');

      return new RSVP.Promise(function (resolve) {
        businessRequestsRef.child(uid).on('value', function (snap) {
          var val = snap.val();

          //console.log('##### REQUESTS CHANGED :', val);

          if (val) {
            var total_reqs = Object.keys(val).length;

            var reqs = Object.keys(val).map(function (req, index) {
              //console.log(val[req]);
              return {
                request_id: Object.keys(val)[index],
                timestamp: val[req].timestamp,
                sender_uid: val[req].sender_uid
              };
            });

            reqs.forEach(function (req, index) {
              var sender_profile_uid = req.sender_uid;

              firebaseApp.database().ref('userProfiles').child(sender_profile_uid).once('value', function (snap) {
                var profile = snap.val();
                //console.log(Object.keys(profile));

                Object.keys(profile).forEach(function (prof_key) {
                  req[prof_key] = profile[prof_key];
                });

                req.name = profile.first_name + ' ' + profile.last_name;

                if (index + 1 === total_reqs) {
                  reqs.reverse();
                  set(self, 'requests', reqs);
                  resolve(reqs);
                }
              });
            });
          } else {
            set(self, 'requests', []);
            resolve([]);
          }
        });
      });
    },
    handleMessages: function handleMessages() {
      var self = this;
      var uid = get(this, 'session').get('uid');
      // let user = get(this, 'user');
      var firebaseApp = get(this, 'firebaseApp');
      var messagesRef = firebaseApp.database().ref('messages');

      console.log('# Service : Notifications : handleMessages');

      return new RSVP.Promise(function (resolve) {
        messagesRef.child(uid).on('value', function (snap) {
          var val = snap.val();

          if (val) {
            var total_msgs = Object.keys(val).length;

            var msgs = Object.keys(val).map(function (msg, index) {
              //console.log(val[req]);
              return {
                message_id: Object.keys(val)[index],
                business: val[msg].business,
                timestamp: val[msg].timestamp,
                sender_uid: val[msg].sender_uid,
                title: val[msg].title,
                text: val[msg].text
              };
            });

            //console.log('# Service : Notifications : msgs :', msgs);

            msgs.forEach(function (msg, index) {
              var dbRef = msg.business ? 'businessProfiles' : 'userProfiles';

              firebaseApp.database().ref(dbRef).child(msg.sender_uid).once('value', function (snap) {
                var profile = snap.val();
                // console.log(Object.keys(profile));
                //console.log(profile);

                Object.keys(profile).forEach(function (prof_key) {
                  msg[prof_key] = profile[prof_key];
                });

                if (profile.name) {
                  msg.name = profile.name;
                } else {
                  msg.name = profile.first_name + ' ' + profile.last_name;
                }

                if (index + 1 === total_msgs) {
                  msgs.reverse();
                  set(self, 'messages', msgs);
                  resolve(msgs);
                }
              });
            });
          } else {
            set(self, 'messages', []);
            resolve([]);
          }
        });
      });
    },
    addEmployee: function addEmployee(userId, jobTitle, isManager, requestObj) {
      var self = this;
      var firebaseApp = get(this, 'firebaseApp');
      var business_id = get(this, 'session').get('uid');
      var total = get(this, 'total');

      console.log('# Service : Notifications : addEmployee');
      console.log('userId :', userId);
      console.log('businessId :', business_id);
      console.log('requestId :', requestObj.request_id);
      console.log('jobTitle :', jobTitle);
      console.log('isManager :', isManager);

      var businessEmployeesRef = firebaseApp.database().ref('businessEmployees').child(business_id);

      var data = {
        created: Date.now(),
        job_title: jobTitle,
        manager: isManager
      };

      return new RSVP.Promise(function (resolve) {
        businessEmployeesRef.child(userId).once('value', function (snapshot) {
          var exists = snapshot.val() !== null;
          //userExistsCallback(userId, exists);
          console.log('# Service : Notifications : addEmployee : exists :', exists);

          if (exists) {
            resolve(true);
          } else {
            // resolve(false);
            businessEmployeesRef.child(userId).set(data).then(function () {
              self.removeRequest(requestObj.request_id).then(function () {
                self.requests.removeObject(requestObj);

                console.log('requests.length :', self.requests.length);

                if (self.requests.length > 0 && total > 0) {
                  self.decrementProperty('total');
                }

                var text = 'Congratulations, you are added as ' + jobTitle + '.';

                if (isManager) {
                  text = 'Congratulations, you are added as manager. Your position is ' + jobTitle + '.';
                }

                var userWorkplacesRef = firebaseApp.database().ref('userWorkplaces').child(userId).child(business_id);

                userWorkplacesRef.set({ pending: false }).then(function () {
                  self.sendMessage(userId, text).then(function () {
                    resolve(false);
                  });
                });
              });
            });
          }
        });
      });
    },
    removeRequest: function removeRequest(requestId) {
      var firebaseApp = get(this, 'firebaseApp');
      var business_id = get(this, 'session').get('uid');
      var businessRef = firebaseApp.database().ref('businessRequests').child(business_id);
      var requestRef = businessRef.child(requestId);

      return requestRef.remove();
    },
    denyRequest: function denyRequest(request) {
      // console.log(request);

      var firebaseApp = get(this, 'firebaseApp');
      var business_id = get(this, 'session').get('uid');
      var businessRef = firebaseApp.database().ref('businessRequests').child(business_id);
      var requestRef = businessRef.child(request.request_id);

      var userWorkplacesRef = firebaseApp.database().ref('userWorkplaces').child(request.sender_uid).child(business_id);

      var msg = 'Your request has been denied';

      this.sendMessage(request.sender_uid, msg);
      userWorkplacesRef.remove();
      requestRef.remove();
    },
    sendMessage: function sendMessage(userId, text) {
      var firebaseApp = get(this, 'firebaseApp');
      var senderId = get(this, 'session').get('uid');
      var messagesRef = firebaseApp.database().ref('messages').child(userId);
      var user = get(this, 'user');

      return messagesRef.push({
        business: user.accountType.business,
        sender_uid: senderId,
        timestamp: Date.now(),
        text: text
      });
    },
    deleteMessage: function deleteMessage(messageObj) {
      var firebaseApp = get(this, 'firebaseApp');
      var user_uid = get(this, 'session').get('uid');
      var messageRef = firebaseApp.database().ref('messages').child(user_uid).child(messageObj.message_id);

      return messageRef.remove();
    }
  });
});