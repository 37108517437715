define('yo-grade/routes/employee-info', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Route = _ember.default.Route,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Route.extend({
    employees: service(),
    workplace: service(),

    model: function model(data) {
      var self = this;
      var employees = get(this, 'employees');
      var workplace = get(this, 'workplace');
      var employee_uid = data.id;

      console.log('????????????????????? EMPLOYEES_INFO : MODEL');

      return new RSVP.Promise(function (resolve) {
        if (workplace.ready) {
          if (employees.ready) {
            self.onEmployeesReady(employee_uid, function (data) {
              resolve(data);
            });
          } else {
            employees.createList().then(function () {
              self.onEmployeesReady(employee_uid, function (data) {
                resolve(data);
              });
            });
          }
        } else {
          workplace.onReady(function () {
            if (employees.ready) {
              self.onEmployeesReady(employee_uid, function (data) {
                resolve(data);
              });
            } else {
              employees.createList().then(function () {
                self.onEmployeesReady(employee_uid, function (data) {
                  resolve(data);
                });
              });
            }
          });
        }
      });
    },
    onEmployeesReady: function onEmployeesReady(employee_uid, callback) {
      console.log('**************** EMPLOYEES READY : data :', employee_uid);
      var employees = get(this, 'employees');
      var staff = employees.staff;
      var management = employees.management;
      var userData = null;

      staff.forEach(function (person) {
        if (person.uid === employee_uid) {
          userData = person;
        }
      });

      management.forEach(function (person) {
        if (person.uid === employee_uid) {
          userData = person;
        }
      });

      console.log(userData);

      if (userData) {
        callback(userData);
      }
    }
  });
});