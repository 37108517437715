define('yo-grade/controllers/profile', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      service = _ember.default.inject.service,
      set = _ember.default.set,
      get = _ember.default.get;
  exports.default = Controller.extend({
    user: service(),
    firebaseApp: service(),
    avatar: service(),

    editPersonalInfo: false,
    editingAvatar: false,
    editingProfile: false,
    showAvatarDialog: false,

    personalInfoProps: ['first_name', 'last_name', 'username', 'country', 'state', 'city', 'zipcode'],

    pi_waiting: false,
    pi_success: false,
    pi_error: false,
    pi_errorMsg: '',

    populateFields: function populateFields() {
      var self = this;
      var model = this.get('model.profile');

      this.personalInfoProps.forEach(function (prop) {
        self.set(prop, model[prop]);
      });
    },
    updateUserProfile: function updateUserProfile(data) {
      var self = this;
      var uid = get(this, 'session.currentUser.uid');
      var firebaseApp = get(this, 'firebaseApp');

      set(self, 'pi_waiting', true);
      set(self, 'pi_error', false);
      set(self, 'pi_success', false);

      if (data.first_name === '') {
        set(self, 'pi_errorMsg', 'First name can\'t be blank');
        set(self, 'pi_error', true);
      } else if (data.last_name === '') {
        set(self, 'pi_errorMsg', 'Last name can\'t be blank');
        set(self, 'pi_error', true);
      } else if (data.username === '') {
        set(self, 'pi_errorMsg', 'Username can\'t be blank');
        set(self, 'pi_error', true);
      } else {
        firebaseApp.database().ref('userProfiles/' + uid).update(data).then(function () {
          set(self, 'pi_success', true);
        }).catch(function (error) {
          set(self, 'pi_errorMsg', error);
          set(self, 'pi_error', true);
        });
      }
    },


    actions: {
      editProfile: function editProfile() {
        this.set('editPersonalInfo', true);
        this.populateFields();
      },
      saveUserProfile: function saveUserProfile() {
        var self = this;
        var user = get(this, 'user');
        var model = this.get('model.profile');
        var propsChanged = [];
        var propsToUpdate = {};

        this.personalInfoProps.forEach(function (prop) {
          if (model[prop] !== self[prop]) {
            propsChanged.push(prop);
          }
        });

        propsChanged.forEach(function (prop) {
          propsToUpdate[prop] = self[prop];
        });

        console.log(propsChanged);
        console.log(propsToUpdate);

        if (propsChanged.length === 0) {
          set(self, 'pi_waiting', true);
          set(self, 'pi_error', true);
          set(self, 'pi_errorMsg', 'No changes');
        } else if (propsChanged.indexOf('username') !== -1) {
          set(self, 'pi_waiting', true);

          user.isUsernameTaken(self.username).then(function () {
            // username if available
            return self.updateUserProfile(propsToUpdate);
          }).catch(function (error) {
            set(self, 'pi_success', false);
            set(self, 'pi_error', true);
            set(self, 'pi_errorMsg', error.message);
          });
        } else {
          self.updateUserProfile(propsToUpdate);
        }
      },
      editAvatar: function editAvatar() {
        set(this, 'editingAvatar', true);
      },
      onAvatarChanged: function onAvatarChanged(data) {
        set(this, 'model.profile.profile_image', data);
      }
    }
  });
});