define("yo-grade/helpers/grade-html", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gradeHtml = gradeHtml;
  function gradeHtml(params) {
    function roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    }

    var type = params[0];
    var grades = params[1];

    var valid_grades = grades.filter(function (grade) {
      return grade.value > 0;
    }).map(function (grade) {
      return grade.value;
    });

    var sum = valid_grades.length ? valid_grades.reduce(function (a, b) {
      return a + b;
    }) : 0;
    var total_grades = valid_grades.length;
    var avg_grade = sum ? roundToTwo(sum / valid_grades.length) : 0;

    var return_value = null;

    switch (type) {
      case 'avg':
        return_value = avg_grade;
        break;

      case 'total':
        return_value = total_grades;
        break;

      case '5':
        var fives = valid_grades.filter(function (grade) {
          return grade === 5;
        }).map(function (grade) {
          return grade.value;
        });
        return_value = fives.length;
        break;

      case '4':
        var fours = valid_grades.filter(function (grade) {
          return grade === 4;
        }).map(function (grade) {
          return grade.value;
        });
        return_value = fours.length;
        break;

      case '3':
        var trees = valid_grades.filter(function (grade) {
          return grade === 3;
        }).map(function (grade) {
          return grade.value;
        });
        return_value = trees.length;
        break;

      case '2':
        var twos = valid_grades.filter(function (grade) {
          return grade === 2;
        }).map(function (grade) {
          return grade.value;
        });
        return_value = twos.length;
        break;

      case '1':
        var ones = valid_grades.filter(function (grade) {
          return grade === 1;
        }).map(function (grade) {
          return grade.value;
        });
        return_value = ones.length;
        break;
    }

    return return_value;
  }

  exports.default = _ember.default.Helper.helper(gradeHtml);
});