define('yo-grade/services/employees', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Service.extend({
    session: service(),
    firebaseApp: service(),
    notifications: service(),
    paperToaster: service(),
    workplace: service(),

    staff: null,
    management: null,

    ready: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.staff = [];
      this.management = [];
    },
    createList: function createList() {
      var self = this;
      var workplace = get(this, 'workplace');
      var uid = workplace.data ? workplace.data.business_id : get(this, 'session.currentUser').uid;

      var management = [];
      var staff = [];

      console.log('# Service : Employees : createList : business_uid :', uid);

      var firebaseApp = get(this, 'firebaseApp');
      var rootRef = firebaseApp.database().ref();
      var employeesRef = rootRef.child('businessEmployees').child(uid);

      return new RSVP.Promise(function (resolve) {
        employeesRef.once('value', function (snap) {
          console.log('SNAP: ', snap.val());

          if (!snap.val()) {
            set(self, 'management', []);
            set(self, 'staff', []);

            set(self, 'ready', true);

            resolve({
              staff: [],
              management: []
            });
          } else {

            var employee_uids = Object.keys(snap.val());
            var total_employees = employee_uids.length;
            var fully_loaded_count = 0;

            employee_uids.forEach(function (employee_uid) {
              var userBusiness = snap.val()[employee_uid];

              var userProfileRef = rootRef.child('userProfiles').child(employee_uid);
              var gradesRef = rootRef.child('businessGrades').child(uid).child(employee_uid);

              userProfileRef.once('value', function (snap) {
                var profileVal = snap.val();
                profileVal.uid = employee_uid;

                gradesRef.once('value', function (_snap) {
                  var gradeObj = self.getGradeObjects(_snap.val());
                  var joinedObj = Object.assign(userBusiness, profileVal);

                  joinedObj.grades = gradeObj;

                  if (joinedObj.manager) {
                    management.addObject(joinedObj);
                  } else {
                    staff.addObject(joinedObj);
                  }

                  fully_loaded_count++;

                  if (fully_loaded_count === total_employees) {
                    set(self, 'management', management);
                    set(self, 'staff', staff);

                    set(self, 'ready', true);

                    resolve({
                      staff: staff,
                      management: management
                    });
                  }
                });
              });
            });
          }
        });
      });
    },
    roundToTwo: function roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    formatGrades: function formatGrades(grades) {
      var valid_grades = grades.filter(function (grade) {
        return grade.value > 0;
      }).map(function (grade) {
        return grade;
      });

      var sum = 0;
      var total = 0;

      valid_grades.forEach(function (grade) {
        sum += grade.value;
        total++;
      });

      return {
        average: this.roundToTwo(sum / total),
        total: total
      };
    },
    getGradeObjects: function getGradeObjects(grades) {
      var obj = {
        all_grades: [],
        comments: []
      };

      if (!grades) {
        return {};
      }

      Object.keys(grades).forEach(function (key) {
        obj.all_grades.push(grades[key]);

        if (grades[key].comment) {
          obj.comments.push(grades[key]);
        }
      });

      obj.comments.sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });

      var formated = this.formatGrades(obj.all_grades);
      obj.average = formated.average;
      obj.total = formated.total;

      return obj;
    },
    deleteEmployee: function deleteEmployee(employee) {
      var user_uid = employee.uid;
      var paperToaster = get(this, 'paperToaster');
      var firebaseApp = get(this, 'firebaseApp');
      var notifications = get(this, 'notifications');
      var business_uid = get(this, 'session.currentUser').uid;
      var rootRef = firebaseApp.database().ref();
      var employeesRef = rootRef.child('businessEmployees').child(business_uid).child(user_uid);
      var userWorkplacesRef = rootRef.child('userWorkplaces').child(user_uid).child(business_uid);

      return new RSVP.Promise(function (resolve) {
        employeesRef.remove().then(function () {
          userWorkplacesRef.remove().then(function () {
            notifications.sendMessage(user_uid, 'Your profile is no longer linked to our business.').then(function () {
              var toasterText = 'You successfully removed the profile of ' + employee.first_name + ' ' + employee.last_name + '.';
              paperToaster.show(toasterText, {
                duration: 7000,
                position: 'top right'
              });
              resolve();
            });
          });
        });
      });
    }
  });
});