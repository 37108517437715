define('yo-grade/components/app-main', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Component.extend({
    session: service(),
    notifications: service(),
    paperSidenav: service(),
    user: service(),
    workplace: service(),
    checking: service(),

    classNames: ['app-container'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('md-card-content').css({
        height: this.$('.inner-sidenav').height()
      });

      this.$(window).on('resize', function () {
        _this.$('md-card-content').css({
          height: _this.$('.inner-sidenav').height()
        });
      });
    },
    swipeRight: function swipeRight() {
      var paperSidenav = get(this, 'paperSidenav');
      paperSidenav.toggle('left-sidenav');
    },


    actions: {
      signOut: function signOut() {
        this.sendAction('signOut');
      },
      goBack: function goBack() {
        window.history.back();
      },
      signUpRestaurant: function signUpRestaurant() {
        this.sendAction('signUpRestaurant');
      },
      goToSignIn: function goToSignIn() {
        this.sendAction('goToSignIn');
      },
      goToChecking: function goToChecking() {
        this.sendAction('goToChecking');
      },
      goToNotifications: function goToNotifications() {
        this.sendAction('goToNotifications');
      }
    }
  });
});