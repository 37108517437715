define('yo-grade/components/custom-dialog', ['exports', 'ember', 'ember-paper/components/paper-dialog'], function (exports, _ember, _paperDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = _ember.default.set;
  exports.default = _paperDialog.default.extend({
    info: null,
    showInfo: false,
    header: true,
    title: null,
    active: null,
    bottomMargin: true,
    onClosed: null,

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.onClosed) {
        this.onClosed();
      }
    },


    actions: {
      close: function close() {
        set(this, 'active', false);
      },
      toggleInfo: function toggleInfo() {
        this.toggleProperty('showInfo');
      }
    }
  });
});