define('yo-grade/components/checkin-history-item', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    classNames: ['checkin-history-item'],
    folded: true,
    hours: null,
    check: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var check = this.get('check');
      var miliseconds_delta = check.out - check.in;
      var hours = miliseconds_delta / (1000 * 60 * 60);

      this.set('hours', _moment.default.duration(hours, 'hours').humanize());
    },
    didInsertElement: function didInsertElement() {
      var self = this;

      this.$().on('click', function () {
        self.toggleProperty('folded');
      });
    }
  });
});