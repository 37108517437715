define('yo-grade/services/ranking', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      observer = _ember.default.observer,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Service.extend({
    firebaseApp: service(),
    session: service(),
    workplace: service(),
    user: service(),

    after_midnight_treshold: 3, // hours

    ready: false,
    workplaceActive: false,

    period: 'this_week',

    onPeriodChange: observer('period', function () {
      var period = get(this, 'period');
      this.createList(period);
    }),

    workers: [],
    managers: [],

    initialize: function initialize() {
      this.createList();
    },
    resetList: function resetList() {
      set(this, 'period', 'this_week');
      set(this, 'workers', []);
      set(this, 'managers', []);
    },
    createList: function createList() {
      var self = this;
      var period = get(this, 'period');
      var uid = get(this, 'session').get('uid');
      var user = get(this, 'user');
      // let sortByDefault = get(this, 'sortBy') === 'default' ? true : false ;
      var workplace = get(this, 'workplace');

      if (user.accountType.user && !workplace.active) {
        set(this, 'ready', true);
        return;
      } else {
        set(this, 'workplaceActive', true);
      }

      var firebaseApp = get(this, 'firebaseApp');
      var db = firebaseApp.database();
      var userProfiles = db.ref('userProfiles');
      var business_id = user.accountType.user ? workplace.data.business_id : uid;
      var businessEmployees = db.ref('businessEmployees').child(business_id);
      var businessGrades = db.ref('businessGrades').child(business_id);
      var workers = [];
      var managers = [];

      console.log('------------------------------------');
      console.log('# Service : Ranking : createList : ', period);
      console.log('------------------------------------');

      // console.log('ACCOUNT TYPE : USER :', user.accountType.user);

      console.log('business_id:', business_id);

      return new RSVP.Promise(function (resolve) {
        businessEmployees.once('value').then(function (snap) {
          var employees = snap.val();
          return employees;
        }).then(function (employees) {
          if (!employees) {
            set(self, 'ready', true);
            return;
          }

          Object.keys(employees).forEach(function (uid, index) {
            var employee_data = employees[uid];

            userProfiles.child(uid).once('value').then(function (snap) {
              var profile_data = snap.val();
              var user_data = Object.assign(employee_data, profile_data);

              return user_data;
            }).then(function (user_data) {
              var period = get(self, 'period');
              var periodObj = self.getPeriod(period);

              // console.log(periodObj);

              businessGrades.child(uid).orderByChild('timestamp').startAt(periodObj.start).endAt(periodObj.end).once('value').then(function (snap) {
                var user_grades_obj = snap.val();
                var user_grades_arr = [];

                // console.log('======================');
                // console.log(user_grades_obj);
                // console.log('======================');

                if (user_grades_obj) {
                  Object.keys(user_grades_obj).forEach(function (grade) {
                    user_grades_arr.push(user_grades_obj[grade].value);
                  });

                  user_data.grades = self.formatGrades(user_grades_arr);
                  // console.log(user_data);
                  if (user_data.manager) {
                    managers.push(user_data);
                  } else {
                    workers.push(user_data);
                  }
                }

                if (Object.keys(employees).length === index + 1) {
                  console.log('LAST EMPLOYEE');
                  var sortedManagers = self.sortList(managers);
                  var sortedWorkers = self.sortList(workers);

                  set(self, 'managers', sortedManagers);
                  set(self, 'workers', sortedWorkers);
                  set(self, 'ready', true);

                  resolve();
                }
              });
            });
          });
        });
      });
    },
    getPeriod: function getPeriod(period) {
      var after_midnight_treshold = get(this, 'after_midnight_treshold');

      var startTime = null;
      var endTime = null;

      switch (period) {
        case 'yesterday':
          startTime = (0, _moment.default)().startOf('day').subtract(1, 'day').add(after_midnight_treshold, 'hours').format('x');
          endTime = (0, _moment.default)().startOf('day').add(after_midnight_treshold, 'hours').format('x');
          break;

        case 'this_week':
          startTime = (0, _moment.default)().startOf('isoWeek').add(after_midnight_treshold, 'hours').format('x');
          endTime = (0, _moment.default)().endOf('isoWeek').add(after_midnight_treshold, 'hours').format('x');
          break;

        case 'last_week':
          startTime = (0, _moment.default)().startOf('isoWeek').subtract(7, 'days').add(after_midnight_treshold, 'hours').format('x');
          endTime = (0, _moment.default)().endOf('isoWeek').subtract(7, 'days').add(after_midnight_treshold, 'hours').format('x');
          break;

        case 'this_month':
          startTime = (0, _moment.default)().startOf('month').add(after_midnight_treshold, 'hours').format('x');
          endTime = (0, _moment.default)().endOf('month').add(after_midnight_treshold, 'hours').format('x');
          break;

        case 'last_month':
          startTime = (0, _moment.default)().startOf('month').subtract(1, 'month').add(after_midnight_treshold, 'hours').format('x');
          endTime = (0, _moment.default)().endOf('month').subtract(1, 'month').add(after_midnight_treshold, 'hours').format('x');
          break;
      }

      return {
        start: parseInt(startTime),
        end: parseInt(endTime)
      };
    },
    sortList: function sortList(list) {
      console.log(list);
      if (list.length) {
        var sorted_1 = list.sort(function (a, b) {
          //console.log(a, b);
          if (a.grades.average > b.grades.average) {
            return -1;
          } else if (a.grades.average < b.grades.average) {
            return 1;
          } else if (a.grades.average === b.grades.average) {
            if (a.grades.total > b.grades.total) {
              return -1;
            } else if (a.grades.total < b.grades.total) {
              return 1;
            } else {
              return 0;
            }
          }
        });

        if (list.length > 5) {
          var first_50_percent = Math.floor(list.length / 2);
          return sorted_1.slice(0, first_50_percent);
        } else {
          return sorted_1;
        }
      } else {
        return [];
      }
    },
    roundToTwo: function roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    formatGrades: function formatGrades(grades) {
      var valid_grades = grades.filter(function (grade) {
        return grade > 0;
      }).map(function (grade) {
        return grade;
      });

      // console.log(valid_grades);

      var sum = 0;
      var total = 0;

      valid_grades.forEach(function (grade) {
        sum += grade;
        total++;
      });

      return {
        average: this.roundToTwo(sum / total),
        total: total
      };
    }
  });
});