define('yo-grade/event_dispatcher', ['exports', 'ember', 'ember-gestures/event_dispatcher', 'yo-grade/config/environment'], function (exports, _ember, _event_dispatcher, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var merge = _ember.default.assign || _ember.default.merge;

  var gestures = merge({}, {
    emberUseCapture: false,
    removeTracking: false,
    useFastPaths: false
  });
  gestures = merge(gestures, _environment.default.gestures);

  exports.default = _event_dispatcher.default.extend({
    useCapture: gestures.emberUseCapture,
    removeTracking: gestures.removeTracking,
    useFastPaths: gestures.useFastPaths
  });
});