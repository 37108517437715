define('yo-grade/utils/has-limited', ['exports', 'emberfire-utils/utils/has-limited'], function (exports, _hasLimited) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hasLimited.default;
    }
  });
});