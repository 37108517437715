define('yo-grade/components/sort-box', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Component.extend({
    unsortedList: null,

    // sortBy: 'average_grade',
    sortBy: 'default',

    reverseSort: true,
    sortDefinition: computed('sortBy', 'reverseSort', function () {
      var sortOrder = get(this, 'reverseSort') ? 'desc' : 'asc';
      var sort_str = get(this, 'sortBy') + ':' + sortOrder;

      return [sort_str];
    }),

    sortedList: computed.sort('unsortedList', 'sortDefinition'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var props = get(this, 'props');
      var labels = get(this, 'labels');
      var sortBy = get(this, 'sortBy');

      if (sortBy === 'default') {
        set(this, 'sortBy', props[0]);
        set(this, 'label', labels[0]);
      }
    },


    actions: {
      toggleSortProp: function toggleSortProp() {
        var sortBy = get(this, 'sortBy');
        var props = get(this, 'props');
        var labels = get(this, 'labels');

        var currentIndex = props.indexOf(sortBy);

        // console.log(currentIndex, props.length);

        if (currentIndex + 1 === props.length) {
          set(this, 'sortBy', props[0]);
          set(this, 'label', labels[0]);
        } else {
          set(this, 'sortBy', props[currentIndex + 1]);
          set(this, 'label', labels[currentIndex + 1]);
        }
      },
      toggleSortDirection: function toggleSortDirection() {
        this.toggleProperty('reverseSort');
      }
    }
  });
});