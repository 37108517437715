define('yo-grade/routes/checking', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Route.extend({
    checking: service(),
    workplace: service(),

    activate: function activate() {
      var workplace = get(this, 'workplace');
      var checking = get(this, 'checking');

      if (workplace.ready) {
        checking.initialize();
      } else {
        workplace.onReady(function () {
          checking.initialize();
        });
      }
    }
  });
});