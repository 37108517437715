define('yo-grade/routes/profile', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Route = _ember.default.Route,
      service = _ember.default.inject.service,
      get = _ember.default.get;
  exports.default = Route.extend({
    user: service(),

    model: function model() {
      // let self = this;
      var user = get(this, 'user');
      // let uid = get(this, 'session.currentUser.uid');

      return new RSVP.Promise(function (resolve, reject) {
        var requests = {
          profile: user.get('profile'),
          grades: user.get('publicGrades')
        };

        RSVP.hash(requests).then(function (data) {
          //console.log(data);
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    deactivate: function deactivate() {
      this.controller.set('editingProfile', false);
      this.controller.set('preloader', false);
      this.controller.set('error_msg', '');
    }
  });
});