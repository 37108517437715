define('yo-grade/components/time-chooser', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      service = _ember.default.inject.service,
      get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Component.extend({
    timeChooser: service(),
    classNames: ['time-chooser layout-align-center-center'],

    dateObj: null,
    dateInitObj: null,
    defaultOffset: null,
    time: null,
    date: null,
    label: null,
    offsetLabel: null,
    offsetHours: null,
    startTime: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var dateObj = (0, _moment.default)();
      var dateInitObj = (0, _moment.default)();
      var defaultOffset = get(this, 'defaultOffset');
      var startTime = get(this, 'startTime');

      if (defaultOffset) {
        dateObj.add(defaultOffset, 'h');
      }

      if (startTime) {
        dateObj = (0, _moment.default)(startTime);
      }

      set(this, 'dateObj', dateObj);
      set(this, 'dateInitObj', dateInitObj);

      this.update();
    },
    roundToTwo: function roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    formatTime: function formatTime(type) {
      var dateObj = get(this, 'dateObj');
      var number = null;

      if (type === 'hours') {
        number = dateObj.hours();
      } else if (type === 'minutes') {
        number = dateObj.minutes();
      }

      if (number < 10) {
        return '0' + number;
      } else {
        return number;
      }
    },
    update: function update() {
      var timeChooser = get(this, 'timeChooser');
      var dateObj = get(this, 'dateObj');
      var dateInitObj = get(this, 'dateInitObj');

      set(this, 'time', dateObj.format('hh:mm a'));
      set(this, 'date', dateObj.format('dddd, MMMM Do'));

      var offsetHours = dateObj.diff(dateInitObj, 'h', true);
      var offesetRounded = this.roundToTwo(offsetHours);
      set(this, 'offsetHours', offesetRounded);

      set(timeChooser, 'time', dateObj);
    },


    actions: {
      increaseHours: function increaseHours() {
        var dateObj = get(this, 'dateObj');
        dateObj.add(1, 'hours');
        this.update();
      },
      decreaseHours: function decreaseHours() {
        var dateObj = get(this, 'dateObj');
        var dateInitObj = get(this, 'dateInitObj');
        var diff = dateObj.diff(dateInitObj, 'h', true);

        if (diff - 1 >= 0) {
          dateObj.subtract(1, 'hours');
          this.update();
        }
      },
      increaseMinutes: function increaseMinutes() {
        var dateObj = get(this, 'dateObj');
        dateObj.add(1, 'minutes');
        this.update();
      },
      decreaseMinutes: function decreaseMinutes() {
        var dateObj = get(this, 'dateObj');
        var dateInitObj = get(this, 'dateInitObj');
        var diff = dateObj.diff(dateInitObj, 'm', true);

        if (diff - 1 >= 0) {
          dateObj.subtract(1, 'minutes');
          this.update();
        }
      }
    }
  });
});