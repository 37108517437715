define('yo-grade/components/app-page-card', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      service = _ember.default.inject.service,
      observer = _ember.default.observer,
      set = _ember.default.set,
      get = _ember.default.get;
  exports.default = Component.extend({
    grading: service(),
    ranking: service(),
    employees: service(),

    classNames: ['app-page-card'],
    classNameBindings: ['preloader'],

    title: null,
    resizeable: null,
    folded: false,
    info: null,
    info_on: false,
    headerAction: null,
    headerActionLabel: null,
    header: true,
    refreshAction: null,
    preloader: false,

    rankListSelectedPeriod: {
      value: 'this_week',
      label: 'This week'
    },

    init: function init() {
      this._super.apply(this, arguments);

      var rankListPeriods = get(this, 'rankListPeriods');

      set(this, 'rankListSelectedPeriod', rankListPeriods[1]);
    },


    onPeriodChange: observer('rankListSelectedPeriod', function () {
      var ranking = get(this, 'ranking');
      var period = get(this, 'rankListSelectedPeriod.value');

      set(ranking, 'period', period);
    }),

    rankListPeriods: [{
      value: 'yesterday',
      label: 'Yesterday'
    }, {
      value: 'this_week',
      label: 'This week'
    }, {
      value: 'last_week',
      label: 'Last week'
    }, {
      value: 'this_month',
      label: 'This month'
    }, {
      value: 'last_month',
      label: 'Last month'
    }],

    // init(){
    //   this._super(...arguments);
    // },


    actions: {
      toggleFolding: function toggleFolding() {
        this.toggleProperty('folded');
      },
      toggleInfo: function toggleInfo() {
        this.toggleProperty('info_on');
      },
      refresh: function refresh() {
        var self = this;
        var refreshAction = get(this, 'refreshAction');

        set(this, 'preloader', true);

        if (refreshAction === 'refreshGrading') {
          var grading = get(this, 'grading');

          grading.refreshGrading().then(function () {
            set(self, 'preloader', false);
          });
        } else if (refreshAction === 'refreshRankList') {
          var ranking = get(this, 'ranking');

          ranking.createList().then(function () {
            set(self, 'preloader', false);
          });
        } else if (refreshAction === 'refreshEmployees') {
          var employees = get(this, 'employees');

          employees.createList().then(function () {
            set(self, 'preloader', false);
          });
        }
      },
      periodChanged: function periodChanged() {
        console.log('PERIOD CHANGED');
      }
    }
  });
});