define('yo-grade/services/user', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP;
  var Service = _ember.default.Service,
      service = _ember.default.inject.service,
      _get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Service.extend({
    session: service(),
    firebaseApp: service(),
    firebaseUtil: service(),

    checking: service(),
    workplace: service(),
    notifications: service(),

    accountType: {
      user: false,
      business: false,
      admin: false
    },

    create: function create(params) {
      var self = this;

      return new RSVP.Promise(function (resolve, reject) {
        self.isUsernameTaken(params.username).then(function () {
          console.log('##### 1 : isUsernameTaken');
          return true;
        }).then(function () {
          console.log('##### 2 : _createFirebaseUser');

          return self._createFirebaseUser(params.email, params.password).then(function (user) {
            var uid = user.uid;
            var tasks = {
              account: self._createAccount(uid, params),
              profile: self._createProfile(uid, params)
            };

            console.log('##### 3 : created : firebase user');

            return RSVP.hash(tasks).then(function () {
              resolve();
            });
          });
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    get: function get(dataKey, _uid) {
      var session = _get(this, 'session');
      var currentUser = session.get('currentUser');
      var uid = _uid || currentUser.uid;
      var firebaseUtil = _get(this, 'firebaseUtil');

      var dbRef = void 0;

      switch (dataKey) {
        case 'profile':
          dbRef = 'userProfiles';
          break;
        case 'account':
          dbRef = 'userAccounts';
          break;
        case 'publicGrades':
          dbRef = 'publicGrades';
          break;
        case 'privateGrades':
          dbRef = 'privateGrades';
          break;
        case 'businessProfile':
          dbRef = 'businessProfiles';
          break;
      }

      console.log('# Service : User : get : ' + dbRef + ' :', uid);

      return new RSVP.Promise(function (resolve, reject) {
        firebaseUtil.findRecord(dbRef, dbRef + '/' + uid).then(function (data) {

          if (dataKey === 'publicGrades') {
            var _data = [];

            Object.keys(data).forEach(function (key) {
              if (key.indexOf('-') !== -1) {
                _data.push(data[key]);
              }
            });

            resolve(_data);
          } else {
            resolve(data);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    setAccountType: function setAccountType() {
      var self = this;
      var firebaseApp = _get(this, 'firebaseApp');
      var userProfilesRef = firebaseApp.database().ref('userProfiles');
      var businessProfilesRef = firebaseApp.database().ref('businessProfiles');
      var session = _get(this, 'session');
      var user_uid = session.get('currentUser').uid;

      console.log('# Service : User : setAccountType');

      return new RSVP.Promise(function (resolve) {

        userProfilesRef.child(user_uid).once('value').then(function (snap) {
          var userProfile = snap.val();

          if (userProfile) {
            console.log('# Service : User : accountType : user');
            set(self, 'accountType.user', true);
            resolve();
          } else {
            businessProfilesRef.child(user_uid).once('value').then(function (snap) {
              var businessProfile = snap.val();

              if (businessProfile) {
                console.log('# Service : User : accountType : business');
                set(self, 'accountType.business', true);
                resolve();
              }
            });
          }
        });

        // self.get('profile').then(profile => {
        //   console.log('# Service : User : profile :', Object.keys(profile).length > 0);
        //
        //   if (Object.keys(profile).length > 0) {
        //     console.log('# Service : User : accountType : user');
        //     set(self, 'accountType.user', true);
        //     resolve();
        //
        //   } else {
        //     console.log('# Service : User : setAccountType : check businessProfiles');
        //     self.get('businessProfile').then(businessProfile => {
        //       console.log('business profile:', Object.keys(businessProfile).length > 0);
        //
        //       if (Object.keys(businessProfile).length > 0) {
        //         set(self, 'accountType.business', true);
        //         console.log('# Service : User : accountType : business');
        //         resolve();
        //       }
        //     });
        //   }

        // }).catch(err => {
        //   reject(err);
        // });
      });
    },
    setup: function setup() {
      var self = this;

      console.log('------------------------------------');
      console.log('# Service : User : setup');
      console.log('------------------------------------');

      var workplace = _get(self, 'workplace');
      var notifications = _get(self, 'notifications');
      var checking = _get(self, 'checking');

      return new RSVP.Promise(function (resolve, reject) {
        self.setAccountType().then(function () {
          if (_get(self, 'accountType.user')) {
            workplace.initialize().then(function () {
              checking.initialize();
              notifications.initialize();
              resolve();
            });
          } else if ('accountType.business') {
            workplace.initialize().then(function () {
              //employees.initialize();
              notifications.initialize();
              resolve();
            });
          }
        }).catch(function (err) {
          reject(err);
        });
      });
    },


    // --------------------------------------------
    // Create Firebase User
    // --------------------------------------------

    _createFirebaseUser: function _createFirebaseUser(email, pass) {
      var auth = _get(this, 'firebaseApp').auth();

      return auth.createUserWithEmailAndPassword(email, pass);
    },


    // --------------------------------------------
    // Create User Account
    // --------------------------------------------

    _createAccount: function _createAccount(uid) {
      var firebaseApp = _get(this, 'firebaseApp');
      var userAccounts = firebaseApp.database().ref('userAccounts');

      var data = {
        created: new Date().getTime()
      };

      return userAccounts.child(uid).set(data);
    },


    // --------------------------------------------
    // Create User Profile
    // --------------------------------------------

    _createProfile: function _createProfile(uid, params) {
      var firebaseApp = _get(this, 'firebaseApp');
      var userProfiles = firebaseApp.database().ref('userProfiles');

      var data = {
        created: new Date().getTime(),
        first_name: params.first_name,
        last_name: params.last_name,
        username: params.username,
        profile_image: ''
      };

      return userProfiles.child(uid).set(data);
    },


    // --------------------------------------------
    // Update Email
    // --------------------------------------------

    updateEmail: function updateEmail(address) {
      //let self = this;
      var currentUser = _get(this, 'session.currentUser');

      return new RSVP.Promise(function (resolve, reject) {
        currentUser.updateEmail(address).then(function (data) {
          console.log('# User : Email Updated :', address);
          console.log(data);
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },


    // --------------------------------------------
    // Helpers
    // --------------------------------------------

    isUsernameTaken: function isUsernameTaken(username) {
      var firebaseApp = _get(this, 'firebaseApp');
      var userProfiles = firebaseApp.database().ref('userProfiles');

      return new RSVP.Promise(function (resolve, reject) {
        userProfiles.orderByChild('username').equalTo(username).once('value').then(function (d) {
          if (d.val()) {
            console.log('--- isUsernameTaken : ', true);
            reject({ message: 'This username is already taken' });
          } else {
            console.log('--- isUsernameTaken : ', false);
            resolve();
          }
        });
      });
    }
  });
});