define('yo-grade/services/-gestures', ['exports', 'ember', 'yo-grade/config/environment', 'ember-gestures/services/-gestures'], function (exports, _ember, _environment, _gestures) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var merge = _ember.default.assign || _ember.default.merge;

  var gestures = merge({}, {
    useCapture: false
  });
  gestures = merge(gestures, _environment.default.gestures);

  exports.default = _gestures.default.extend({
    useCapture: gestures.useCapture
  });
});