define('yo-grade/components/gradable-user', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      service = _ember.default.inject.service,
      set = _ember.default.set,
      get = _ember.default.get;
  exports.default = Component.extend({
    grading: service(),
    paperToaster: service(),

    classNames: ['gradable-user'],
    grade: 4,
    selected: false,
    nei: false,
    showPreloader: false,
    showComment: false,
    comment: '',

    user: null,

    actions: {
      toggleGrading: function toggleGrading() {
        this.toggleProperty('selected');

        var selected = get(this, 'selected');

        if (!selected) {
          set(this, 'grade', 4);
          set(this, 'nei', false);
        }
      },
      toggleComment: function toggleComment() {
        this.toggleProperty('showComment');
      },
      setGrade: function setGrade(gradeValue) {
        set(this, 'grade', gradeValue);

        if (gradeValue === 0) {
          set(this, 'nei', true);
        } else {
          if (gradeValue === 1 || gradeValue === 2) {
            set(this, 'showComment', true);
          } else {
            set(this, 'showComment', false);
          }

          set(this, 'nei', false);
        }
      },
      confirmGrade: function confirmGrade(id) {
        var grading = get(this, 'grading');
        var paperToaster = get(this, 'paperToaster');
        var grade = get(this, 'grade');
        var first_name = get(this, 'user.first_name');
        var last_name = get(this, 'user.last_name');
        var comment = get(this, 'comment');
        var error_msg = 'Comment is required for one and two star grades';
        var success_msg = 'You successfully graded ' + first_name + ' ' + last_name;

        if ((grade === 1 || grade === 2) && !comment) {
          paperToaster.show(error_msg, {
            duration: 7000,
            position: 'bottom right'
          });
        } else {
          set(this, 'showPreloader', true);

          grading.gradeUser(id, grade, comment).then(function () {
            paperToaster.show(success_msg, {
              duration: 3000,
              position: 'bottom right'
            });
          });
        }
      }
    }
  });
});